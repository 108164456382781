// sale_percentage_int is not always set in hits (most often not) so we calculate % like this
function discountPercentage (salePrice, price) {
  const salePercentage = (salePrice / price - 1) * 100
  return `${salePercentage.toFixed()}%`
}

const formatter = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0
})

function formattedPrice (price) {
  return formatter.format(price)
}

export function showTradePrice (hit, isTradeBuyer) {
  return hit.trade_price_and_trade_price_under_sale_price && isTradeBuyer
}

// !sold in here to avoid future problem with this being reused to render listing tiles in wishlists
export function showSalePrice (hit, isTradeBuyer) {
  return hit.sale_price && hit.sale && !hit.sold && !showTradePrice(hit, isTradeBuyer)
}

export function renderListingPrice (hit, isTradeBuyer) {
  if (showTradePrice(hit, isTradeBuyer)) {
    return `
      <div class="vsd-listing__price--strikethrough" aria-label="base price">${formattedPrice(hit.price)}</div>
      <div class="vsd-listing__trade-percentage" aria-label="trade discount percentage">${discountPercentage(hit.ometria_algolia_trade_price, hit.price)}</div>
      <div class="vsd-listing__trade-price" aria-label="trade price">${formattedPrice(hit.ometria_algolia_trade_price)}</div>
    `
  } else if (showSalePrice(hit, isTradeBuyer)) {
    return `
      <div class="vsd-listing__price--strikethrough" aria-label="base price">${formattedPrice(hit.price)}</div>
      <div class="vsd-listing__sale-percentage" aria-label="discount percentage">${discountPercentage(hit.sale_price, hit.price)}</div>
      <div class="vsd-listing__sale-price" aria-label="sale price">${formattedPrice(hit.sale_price)}</div>
    `
  } else {
    return `<div class="vsd-listing__price" aria-label="price">${formattedPrice(hit.price)}</div>`
  }
}
