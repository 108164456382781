import { checkEmailIsValid } from './utils'
import { formDataFetch } from './fetchHelpers'
import { trackSubscription } from '../analytics/events/user'

export default function newsLetterSignUp () {
  const signUpForms = document.querySelectorAll('.vjs-newsletter-sign-up-form')
  signUpForms.forEach(form => form.addEventListener('submit', e => handleSignUpSubmit(e)))
  plpTileNewsLetterSignUp()
  trackRegFormNewsLetterOptIn()
  trackPostRegModalNewsLetterOptIn()
}

function handleSignUpSubmit (e) {
  const { target } = e
  const emailValue = target.querySelector('input[name="data[email]"]').value
  const signUpSource = target.querySelector('input[name="data[sign_up_source]"]').value
  const submitStatusMessage = target.parentElement.querySelector('.vjs-newsletter-form__status')
  const invalidClass = 'vsd-newsletter-form__status--invalid'

  e.preventDefault()
  submitStatusMessage.classList.remove('invisible')

  if (!checkEmailIsValid(emailValue)) {
    submitStatusMessage.classList.add(invalidClass)
    submitStatusMessage.innerText = 'Invalid email address'
    return
  }

  formDataFetch(target)
    .then(() => {
      submitStatusMessage.classList.remove(invalidClass)
      submitStatusMessage.innerText = 'Thank you for submitting your email!'

      trackSubscription(signUpSource)
    })
    .catch(() => {
      submitStatusMessage.classList.add(invalidClass)
      submitStatusMessage.innerText = 'Sorry there was an error when trying to submit your email'
    })
}

function trackRegFormNewsLetterOptIn () {
  const registrationForm = document.querySelector('.vjs-registration-form')
  if (!registrationForm) return

  registrationForm.addEventListener('submit', () => {
    const newsLetterOptInCheckbox = document.getElementById('vjs-user-marketing-opt-in')

    if (!registrationForm.checkValidity()) return
    if (newsLetterOptInCheckbox && newsLetterOptInCheckbox.checked) {
      const signUpSource = 'registration_form_newsletter_sign_up_checkbox'
      trackSubscription(signUpSource)
    }
  })
}

function trackPostRegModalNewsLetterOptIn () {
  const postRegModal = document.querySelector('.vjs-post-registration-modal')
  if (!postRegModal) return

  postRegModal.addEventListener('submit', () => {
    const newsLetterOptInCheckbox = document.getElementById('vjs-post-reg-modal-marketing-opt-in')

    if (newsLetterOptInCheckbox && newsLetterOptInCheckbox.checked) {
      const signUpSource = 'post_registration_modal_newsletter_sign_up_checkbox'
      trackSubscription(signUpSource)
    }
  })
}

export function plpTileNewsLetterSignUp () {
  const tileSignUpForm = document.getElementById('vjs-tile-subscribe-form')
  if (tileSignUpForm) tileSignUpForm.addEventListener('submit', e => handleSignUpSubmit(e))
}
