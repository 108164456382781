/* global IntersectionObserver */

export function checkEmailIsValid (email) {
  // taken from http://emailregex.com/ (with some unnecessary escape chars removed)
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
}

export function tuplesToObjects (arrayOfTuples, leftKeyName, rightKeyName) {
  if (!leftKeyName || !rightKeyName) return
  if (arrayOfTuples.some(array => array.length !== 2)) return

  return arrayOfTuples.map(tuple => {
    const [leftValue, rightValue] = tuple
    return {
      [leftKeyName]: leftValue,
      [rightKeyName]: rightValue
    }
  })
}

// TODO: Consider polyfill at some point
// https://github.com/GoogleChromeLabs/intersection-observer
export function onVisible (element, callback) {
  if (!element) return
  if (!('IntersectionObserver' in window)) return

  new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      if (entry.intersectionRatio > 0) {
        callback(element)
        observer.disconnect()
      }
    })
  }, { root: null }).observe(element)
}
