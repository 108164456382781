import initNewListingsSections from '../../src/newListingsSections'
import initRecentModules from '../../src/relatedListings/recentItemsModules'

document.addEventListener('DOMContentLoaded', () => {
  initNewListingsSections()
  initRecentModules()
  import(/* webpackChunkName: "carousels" */ '../../src/carousels').then(module => {
    module.initHomepageMobileHeroCarousel()
  })
})
