import { tuplesToObjects } from '../utils'
import { renderListingImage } from './listingImageRenderer'
import { renderListingPrice, showSalePrice, showTradePrice } from './listingPriceRenderer'
import renderWishlistButton from './wishlistButtonRenderer'
import godsEye from '../../images/icons/gods-eye.svg'

export function getWishlistedItemsFromPage () {
  const wishlistedTuplesValue = document.querySelector('.vjs-wishlist-item-ids').value
  const wishlistedTuples = wishlistedTuplesValue.length > 0 ? JSON.parse(wishlistedTuplesValue) : []
  return tuplesToObjects(wishlistedTuples, 'wishlistID', 'listingID')
}

export function getHitHTML (hit, wishlistedItems, userStatus, tileOptions) {
  const { objectID, slug, title } = hit
  // StandardJS doesn't like snake case variable e.g: free_uk_delivery_for_algolia
  const freeLondonOrUkDelivery = hit.free_london_or_uk_delivery
  const { isTradeBuyer, isLoggedIn } = userStatus
  const { styleModifier = '', plpPage = false, homepage = false } = tileOptions
  const hrefFromHit = `/${hit.path_hierarchy}/${slug}`
  const similarQuery = encodeURIComponent(title)
  const similarQueryFromHit = `/${hit.path_hierarchy}?similar_query=${similarQuery}`
  const wishlistedHit = wishlistedItems.find(item => item.listingID === parseInt(objectID))
  const wishlistedHitID = wishlistedHit ? wishlistedHit.wishlistID : null
  const openNewTabEl = document.querySelector('#vjs-open-listing-new-tab')
  const openNewTab = JSON.parse(openNewTabEl?.value)
  const isDesktopEl = document.querySelector('#vjs-device-desktop')
  const isDesktop = JSON.parse(isDesktopEl?.value)

  // data-productid is picked up by a Visibility Trigger on GTM
  return `
    <div class="vsd-listing__container vjs-results-product-click"
      data-productid="${objectID}"
      ${listingTileDataAttributes(hit)}
    >
      <a class="vsd-listing__image-container${styleModifier}" ${openNewTab && isDesktop ? 'target="_blank"' : ''} href="${hrefFromHit}" ${listingTileDataAttributes(hit)}>
        ${showSalePrice(hit, isTradeBuyer) ? '<div class="vsd-listing__sale-tag vsd-badge__sale">Sale</div>' : ''}
        ${showTradePrice(hit, isTradeBuyer) ? '<div class="vsd-listing__trade-tag vsd-badge__trade">Trade</div>' : ''}
        ${renderListingImage(hit)}
      </a>
      ${!homepage ? `<span data-masked-url="${similarQueryFromHit}" class="vsd-listing__similar-tag vjs-masked-url vjs-similar-query">View similar <img src="${godsEye}" class="inline"></span>` : ''}
      <div class="vsd-listing__info-container${styleModifier}">
        <a class="vsd-listing__click-area" ${openNewTab && isDesktop ? 'target="_blank"' : ''} href="${hrefFromHit}" ${listingTileDataAttributes(hit)}></a>
        <div class="flex flex-col w-full">
          <a class="vsd-listing__title grow vjs-results-product-click w-4/5" ${openNewTab && isDesktop ? 'target="_blank"' : ''} href="${hrefFromHit}" ${listingTileDataAttributes(hit)}>
            ${title}
          </a>
          <a class="grow ga-ee-product-click" ${openNewTab && isDesktop ? 'target="_blank"' : ''} href="${hrefFromHit}" ${listingTileDataAttributes(hit)}>
            ${renderListingPrice(hit, isTradeBuyer)}
          </a>
        </div>
        ${renderWishlistButton(objectID, wishlistedHitID, isLoggedIn, 'small')}
        ${plpPage && freeLondonOrUkDelivery ? '<div class="vsd-badge__success mt-4 mr-4">FREE DELIVERY</div>' : ''}
      </div>
    </div>
  `
}

function listingTileDataAttributes (hit) {
  const dataAttributes = `
    data-id='${hit.objectID}'
    data-title='${hit.title}'
    data-price='${hit.price}'
    data-brand='${hit.brand_name}'
    data-category='${hit.category_name}'
    data-designer='${hit.designed_by_name}'
    data-promotion_level='${hit.promotion_level}'
    data-position='${hit.__position}'
    data-queryid='${hit.__queryID}'
  `
  return dataAttributes
}

export function getValueUSPTile () {
  return `
    <div class="vsd-listings__content-tile bg-atlantic-green rounded max-599:col-span-2 max-599:rounded-[0]">
      <div class="flex flex-col text-center">
        <img class="rounded-t max-599:hidden" src="https://assets-vinterior-0.vinterior.co/static-assets/value_tile_plp.png" />
        <div class="text-white my-20 mx-16">
          <h4 class="font-medium mb-8 max-599:text-[20px]">
            Did you know?
          </h4>
          <div>
            The average Vinterior piece is 100 years old, whereas flat-pack typically lasts just 3.
            <br>Don't buy new, buy better.
          </div>
        </div>
      </div>
    </div>
  `
}

export function getTradeTile () {
  return `
    <div class="vsd-listings__content-tile bg-atlantic-green rounded max-599:col-span-2 max-599:rounded-[0]">
      <div class="flex flex-col text-center">
        <img class="rounded-t max-599:h-[152px] max-599:rounded-[0] max-599:object-cover" src="https://assets-vinterior-0.vinterior.co/static-assets/trade-support.jpg" />
        <div class="text-white mt-32 mb-24 mx-16">
          <div>Our in-house specialists offer end to end support, procurement and more.</div>
        </div>
        <button class="vsd-button__tonal-lime mx-16 mb-32 text-atlantic-green vjs-trade-tile-cta vjs-amplitude-custom-click" data-amplitude-component-name="VIP tile - launch chat CTA">
          Chat with us
        </button>
      </div>
    </div>
  `
}
