import { pushToFacebookCAPI } from '../PushToFacebookCAPI'
import { pushToDataLayer } from '../PushToDataLayer'
import { getActionEventId } from '../AnalyticsHelpers'

export function trackSubscription (source) {
  const actionEventId = getActionEventId()

  pushToFacebookCAPI('Lead', '', actionEventId)
  pushToDataLayer('leadGenSignUp (Vinterior)', {
    lead: { source: source },
    fb_action_event_id: actionEventId
  })
}

export function trackTradeProgramIntent () {
  pushToDataLayer('ga - tradeProgramNavCTAClicked')
}

export function trackOauthButtonClick (oauthProvider) {
  pushToDataLayer('oauthButtonClick (Vinterior)', {
    ecommerce: {
      click: {
        oauth_provider: oauthProvider
      }
    }
  })
}
