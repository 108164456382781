export function initTradeTile () {
  const tradeCTA = document.querySelector('.vjs-trade-tile-cta')
  if (!tradeCTA) return
  tradeCTA.addEventListener('click', () => openChatWindow())
}

function openChatWindow () {
  if (typeof window.$chatwoot !== 'undefined') {
    window.$chatwoot.toggle('open')
  }
}
