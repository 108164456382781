// TODO: left data-source={source} out of <img> etc data attributes paired with ga-ee
export function renderListingImage (hit) {
  const imageUrlsObject = imageUrls(hit)
  const webpImageUrl = imageUrlsObject.webp
  const jpgImageUrl = imageUrlsObject.jpg
  const lazyImage = hit.__position > 6

  return `
    <picture ${lazyImage ? 'class=\'vjs-image-lazy\'' : ''}>
      <source
        ${lazyImage ? `data-srcset='${webpImageUrl}'` : `srcSet='${webpImageUrl}'`}
        type='image/webp'
      />
      <img
        ${lazyImage ? `data-srcset='${jpgImageUrl}'` : `src='${jpgImageUrl}'`}
        alt='${hit.title}'
        importance='high'
      />
    </picture>`
}

export function initLazyLoadedImages () {
  const lazyImages = document.querySelectorAll('.vjs-image-lazy > source')

  if ('IntersectionObserver' in window) {
    observeLazyImagesFocus(lazyImages)
  } else {
    // TODO: Polyfill this?
    // Not supported, load all images immediately
    lazyImages.forEach((image) => { image.nextElementSibling.src = image.nextElementSibling.dataset.srcset })
  }
}

function observeLazyImagesFocus (lazyImages) {
  const lazyImageObserver = new window.IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const lazyImage = entry.target
        lazyImage.srcset = lazyImage.dataset.srcset
        lazyImage.nextElementSibling.srcset = lazyImage.nextElementSibling.dataset.srcset
        lazyImage.parentElement.classList.remove('vjs-image-lazy')
        lazyImageObserver.unobserve(lazyImage)
      }
    })
  }, { root: null })

  lazyImages.forEach(image => lazyImageObserver.observe(image))
}

// equivalent to Ruby's dig - safely navigate down a hash/object properties
function dig (obj, keys) {
  return keys.split('.').reduce((acc, key) => acc?.[key], obj)
}

function imageUrls (hit) {
  const jpgImage = dig(hit, 'main_photo.image.medium.url')
  const webpImage = dig(hit, 'main_photo.image.medium_webp.url')

  return {
    jpg: convertDomainShard(jpgImage, hit.__position),
    webp: convertDomainShard(webpImage, hit.__position)
  }
}

function convertDomainShard (imageUrl, position) {
  if (!imageUrl) return
  // Make sure we load the first listing image on PLP from shard 0
  // to make sure the image is in the same domain as the one we preloaded in the Head
  if (position > 1) return imageUrl

  // we don't want it running this way in dev due to how images are accessed
  if (process.env.NODE_ENV === 'development') return imageUrl

  const middlePartOfUrl = '.vinterior.co'
  const [firstPartOfURL, lastPartOfUrl] = imageUrl.split(middlePartOfUrl)
  const updatedFirstPartOfURL = firstPartOfURL.slice(0, -1) + '0'
  const updatedFullUrl = updatedFirstPartOfURL + middlePartOfUrl + lastPartOfUrl

  return updatedFullUrl
}
