import { initiateSearch } from '../utils/search/helpers'
import { getWishlistedItemsFromPage, getHitHTML } from './listing_tile_rendering/listingTileUtils'
import setUpAddToWishlistBehaviour from './addToWishlist'
import { setOpenModalButtons } from './modals'
import setMaskedUrls from './maskedUrls'

export default function initNewListingsSections () {
  const newListingSections = Array.from(document.querySelectorAll('.vjs-new-listings'))
  if (newListingSections.length) loadNewListingsIntoPlaceholder(newListingSections[0])
}

function loadNewListingsIntoPlaceholder (placeholder) {
  initiateSearch().then(results => {
    const cappedHits = results.hits.slice(0, 20)
    const wishlistedItems = getWishlistedItemsFromPage()
    const isTradeBuyer = JSON.parse(placeholder.getAttribute('data-trade-buyer'))
    const isLoggedIn = JSON.parse(document.querySelector('body').getAttribute('data-logged-in'))
    const userStatus = { isTradeBuyer, isLoggedIn }
    const tileOptions = {
      homepage: true
    }
    const carouselTiles = cappedHits.map(hit => getHitHTML(hit, wishlistedItems, userStatus, tileOptions))
    placeholder.innerHTML = carouselTiles.join('')

    import(/* webpackChunkName: "carousels" */ './carousels').then(module => module.homepageNewListings())
    setOpenModalButtons()
    setUpAddToWishlistBehaviour()
    setMaskedUrls()
  })
}
