/* global localStorage */
import renderAndEnableListingTiles from '../listing_tile_rendering/listingTileRenderer'
import { buildFilters, initiateSearch } from '../../utils/search/helpers'
import { onVisible } from '../utils'

export default function initRecentModules () {
  loadRecentListings()
  loadRecentSearches()
}

function loadRecentListings () {
  const recentSectionContainers = document.querySelectorAll('.vjs-algolia-recent-section')

  recentSectionContainers.forEach(container => {
    if (container.dataset.lazyLoaded === 'true') {
      onVisible(container, loadItemsIntoContainer)
    } else {
      loadItemsIntoContainer(container)
    }
  })
}

function loadItemsIntoContainer (container) {
  const { hitsPerPage, listingIds } = container.dataset
  const recentListingsFilters = buildFilters({ listingIds })
  const nodeEnv = process.env.NODE_ENV

  initiateSearch(recentListingsFilters, `Listing_${nodeEnv}`).then(results => {
    const hits = results.hits.slice(0, hitsPerPage)
    if (hits.length < 1) {
      container.parentElement?.remove()
    } else {
      renderAndEnableListingTiles(container, hits)
    }
  })
}

function loadRecentSearches () {
  const recentSearchesVal = localStorage.getItem('AUTOCOMPLETE_RECENT_SEARCHES:RECENT_SEARCH')
  if (!recentSearchesVal) return
  const listElement = document.getElementById('vjs-recent-queries')
  if (!listElement) return
  const recentSearchesList = JSON.parse(recentSearchesVal)
  if (recentSearchesList) {
    recentSearchesList.slice(0, 10).forEach(search => {
      const listItem = document.createElement('li')
      const anchor = document.createElement('a')
      anchor.href = `https://www.vinterior.co/search?q=${search.id}`
      anchor.textContent = search.label
      anchor.target = '_blank'
      anchor.classList.add('whitespace-nowrap')
      listItem.appendChild(anchor)
      listItem.classList.add('mx-16')
      listItem.classList.add('my-16')
      listElement.appendChild(listItem)
    })
    const listElementWrapper = document.getElementById('vjs-recent-queries-wrapper')
    listElementWrapper.classList.remove('hidden')
  }
}
