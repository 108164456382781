// TODO: Move renderers to folder
import { initLazyLoadedImages } from './listingImageRenderer'
import { setOpenModalButtons } from '../modals'
import { getWishlistedItemsFromPage, getHitHTML, getValueUSPTile, getTradeTile } from './listingTileUtils'
import { initTradeTile } from '../tradeChat'
import { plpTileNewsLetterSignUp } from '../newsLetterSignUp'

import setUpAddToWishlistBehaviour from '../addToWishlist'
import setMaskedUrls from '../maskedUrls'

export default function renderAndEnableListingTiles (placeholderElement, hits, tileOptions = {}) {
  const wishlistedItems = getWishlistedItemsFromPage()
  // TODO: Check this again later when adding cache - we always set trade to false on recommended listing sections
  const isTradeBuyer = JSON.parse(placeholderElement.getAttribute('data-trade-buyer'))
  const isLoggedIn = JSON.parse(document.querySelector('body').getAttribute('data-logged-in'))
  const userStatus = { isTradeBuyer, isLoggedIn }
  const hitsHtml = hits.map(hit => getHitHTML(hit, wishlistedItems, userStatus, tileOptions))

  addContentTile(tileOptions, hitsHtml, isTradeBuyer)
  placeholderElement.innerHTML = hitsHtml.join('')

  // Not inside addContentTile() because can't happen until all the hits (inc trade tile) are actually rendered
  if (isTradeBuyer && tileOptions.plpPage) initTradeTile()

  initLazyLoadedImages()
  setOpenModalButtons()
  setUpAddToWishlistBehaviour()
  plpTileNewsLetterSignUp()
  setMaskedUrls()

  // Rui: Hack to get GTM Visibility Trigger (watching DOM changes) to wake up and continue tracking
  placeholderElement.toggleAttribute('data-productid')
}

function addContentTile (tileOptions, hitsHtml, isTradeBuyer) {
  if (isTradeBuyer && tileOptions.plpPage) {
    placeTileIn5thPosition(hitsHtml, getTradeTile())
  } else if (tileOptions.showValueUSPTile) {
    placeTileIn5thPosition(hitsHtml, getValueUSPTile())
  }
}

function placeTileIn5thPosition (hitsHtml, contentTile) {
  if (hitsHtml.length > 4 && contentTile) {
    hitsHtml.splice(4, 0, contentTile)
  }
}
